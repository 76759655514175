import React from 'react';
import { KeyValueObject } from '../../types/shared';

export default function InstagramIcon({ className }: KeyValueObject<string>) {
  return (
    <svg className={className} width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path d="M28.3807 0L11.6194 0C5.21242 0 0 5.21242 0 11.6194L0 28.3806C0 34.7876 5.21242 40 11.6194 40L28.3806 40C34.7876 40 40 34.7876 40 28.3807L40 11.6194C40 5.21242 34.7876 0 28.3807 0ZM36.875 28.3806C36.875 33.0645 33.0645 36.875 28.3807 36.875L11.6194 36.875C6.93555 36.875 3.125 33.0645 3.125 28.3807L3.125 11.6194C3.125 6.93555 6.93555 3.125 11.6194 3.125L28.3806 3.125C33.0645 3.125 36.875 6.93555 36.875 11.6194L36.875 28.3806Z" fill="white" />
      <path d="M20 9.21875C14.0552 9.21875 9.21875 14.0552 9.21875 20C9.21875 25.9448 14.0552 30.7812 20 30.7812C25.9448 30.7812 30.7812 25.9448 30.7812 20C30.7812 14.0552 25.9448 9.21875 20 9.21875ZM20 27.6562C15.7784 27.6562 12.3437 24.2216 12.3437 20C12.3437 15.7784 15.7784 12.3437 20 12.3437C24.2216 12.3437 27.6562 15.7784 27.6562 20C27.6562 24.2216 24.2216 27.6562 20 27.6562Z" fill="white" />
      <path d="M30.9375 10.625C31.8004 10.625 32.5 9.92545 32.5 9.0625C32.5 8.19956 31.8004 7.5 30.9375 7.5C30.0746 7.5 29.375 8.19956 29.375 9.0625C29.375 9.92545 30.0746 10.625 30.9375 10.625Z" fill="white" />
    </svg>
  )
}
