import React from 'react';
import { KeyValueObject } from '../../types/shared';

export default function SigninIcon({ className }: KeyValueObject<string | undefined>) {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" className={`d-icon ${className}`}>
      <g filter="url(#filter0_d)">
        <path d="M25.3389 16.1611C23.9774 14.7997 22.3569 13.7918 20.5888 13.1817C22.4825 11.8775 23.7266 9.69473 23.7266 7.22656C23.7266 3.24185 20.4847 0 16.5 0C12.5153 0 9.27344 3.24185 9.27344 7.22656C9.27344 9.69473 10.5175 11.8775 12.4112 13.1817C10.6432 13.7918 9.02266 14.7997 7.66118 16.1611C5.30024 18.5221 4 21.6611 4 25H5.95312C5.95312 19.1844 10.6844 14.4531 16.5 14.4531C22.3156 14.4531 27.0469 19.1844 27.0469 25H29C29 21.6611 27.6998 18.5221 25.3389 16.1611ZM16.5 12.5C13.5922 12.5 11.2266 10.1344 11.2266 7.22656C11.2266 4.31875 13.5922 1.95312 16.5 1.95312C19.4078 1.95312 21.7734 4.31875 21.7734 7.22656C21.7734 10.1344 19.4078 12.5 16.5 12.5Z" fill="white" />
      </g>
      <defs>
        <filter id="filter0_d" x="0" y="0" width="33" height="33" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
