import React from 'react'
import { KeyValueObject } from '../../types/shared'

export default function ContactIcon({ className }: KeyValueObject<string>) {
  return (
    <svg className={className} width="37" height="25" viewBox="0 0 37 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.8442 25H3.69423C1.65769 25 0 23.275 0 21.1538V3.84615C0 1.725 1.65769 0 3.69423 0H32.8442C34.8808 0 36.5385 1.725 36.5385 3.84615V21.1538C36.5385 23.275 34.8808 25 32.8442 25ZM3.69423 1.92308C2.71731 1.92308 1.92308 2.78654 1.92308 3.84615V21.1538C1.92308 22.2135 2.71731 23.0769 3.69423 23.0769H32.8442C33.8212 23.0769 34.6154 22.2135 34.6154 21.1538V3.84615C34.6154 2.78654 33.8212 1.92308 32.8442 1.92308H3.69423Z" fill="white" />
      <path d="M18.2692 15.375C17.6346 15.375 17.0019 15.2212 16.425 14.9135L0.509588 6.42503C0.0403575 6.17503 -0.136566 5.59234 0.113434 5.12311C0.363434 4.6558 0.946127 4.47888 1.41536 4.72695L17.3307 13.2154C17.9192 13.5289 18.623 13.5289 19.2115 13.2154L35.1269 4.72695C35.5961 4.4808 36.1769 4.6558 36.4288 5.12311C36.6788 5.59234 36.5019 6.17311 36.0327 6.42503L20.1173 14.9135C19.5365 15.2212 18.9038 15.375 18.2692 15.375Z" fill="white" />
    </svg>
  )
}
