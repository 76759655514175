import React, { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { graphql, Link, useStaticQuery } from 'gatsby';
import ContactIcon from '../svg/contact';
import InstagramIcon from '../svg/instagram';
import YoutubeIcon from '../svg/youtube';

import facebookIcon from '../../assets/icons/facebook.svg';
import twitterIcon from '../../assets/icons/twitter.svg';
import globalLinks from "../../assets/data/links.json";

import './style.css';
import Image from '../image';

export default function Footer() {
  const [year, setYear] = useState(new Date().getFullYear());
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.svg" }) {
        publicURL
      }
      appleStore: file(relativePath: { eq: "footer/app-store.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      googlePlay: file(relativePath: { eq: "footer/google-play.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  /**
   * on scroll event wrapper with debounce to avoid triggering this event each time user scroll
   */
  const onScroll = debounce(() => {
    if (window) {
      const { pageYOffset } = window
      const $header = document.querySelector('.navbar-wrapper');
      if (!$header?.classList.contains('size_small') && pageYOffset > 0) {
        $header?.classList.add('size_small');
      } else if (pageYOffset === 0) {
        $header?.classList.remove('size_small');
      }
    }

  }, 200);

  useEffect(() => {
    window && window.addEventListener('scroll', onScroll);
    const newYear = new Date().getFullYear();
    if (newYear !== year) {
      console.log("update year")
      setYear(newYear);
    }
    return () => {
      window && window.removeEventListener('scroll', onScroll);
    }
  }, []);

  return (
    <footer className='text-white bg-darkengrey'>

      <div className="footer__block navbar__item">

        <div className="d-flex flex-column flex-md-row align-items-center mb-3 mb-md-5">

          <Link title="page d'accueil" className="flex_1 mb-3 mb-md-0" to='/'>
            <img height={100} width={330} className='img-fluid' src={data?.placeholderImage?.publicURL} />
          </Link>

          <div className="flex_1 h-100px text-center contact-block text-uppercase mb-3 mb-md-0 d-flex justify-content-center align-items-end">
            <div className='d-block d-md-inline-block'>
              <span className='d-flex flex-row align-items-center mb-1 mb-md-3'>
                <ContactIcon className='mr-1 mr-md-3' />
                <span className='fsz-22 d-bold pt-1 nav-item'>
                  Contact
                </span>
              </span>
              <a href="mailto://support@dropzik.com" className='nav-link text-white'>support@dropzik.com</a>
            </div>
          </div>

          <div className="flex_1 social-media__block d-flex justify-content-end align-items-center">
            <a className='mr-3 mr-md-4' title='instagram' href={globalLinks.social.instagram} target='_blank' rel='noopener noreferrer'>
              <InstagramIcon />
            </a>
            <a className='mr-3 mr-md-4' title='facebook' href={globalLinks.social.facebook} target='_blank' rel='noopener noreferrer'>
              <img src={facebookIcon} alt="facebook" />
            </a>
            <a className='mr-3 mr-md-4' title='youtube' href={globalLinks.social.youtube} target='_blank' rel='noopener noreferrer'>
              <YoutubeIcon />
            </a>
            <a className='mr-0' title='twitter' href={globalLinks.social.twitter} target='_blank' rel='noopener noreferrer'>
              <img src={twitterIcon} alt="twitter" />
            </a>
          </div>

        </div>

        <div className="mobile-stores d-flex justify-content-center align-items-center flex-column flex-sm-row">
          <a className='mx-2 mb-2 mb-md-0' href={globalLinks.stores.appstore} title='Application Dropzik en app store' target="_blank" rel='noopner noreferer'>
            <Image
              className='footer__image-store'
              src={data.appleStore?.childImageSharp?.fluid}
              alt='Dropzik en app store'
            />
          </a>
          <a className='mx-2' href={globalLinks.stores.googleplay} title='Application Dropzik en google play' target="_blank" rel='noopner noreferer'>
            <Image
              className='footer__image-store'
              src={data.googlePlay?.childImageSharp?.fluid}
              alt='Dropzik en google play'
            />
          </a>
        </div>

        <ul className="mb-3 mb-md-5 list-unstyled font-weight-light w-100 d-flex justify-content-center align-items-center flex_1">
          <li className="navbar__item nav-item mb-2 mb-lg-0 ml-2 ml-md-5">
            <Link className="nav-link text-uppercase" to={globalLinks.home}>
              Accueil
            </Link>
          </li>
          <li className="navbar__item nav-item mb-2 mb-lg-0 ml-2 ml-md-5">
            <Link className="nav-link text-uppercase" to={globalLinks.prix}>
              Prix
            </Link>
          </li>
          <li className="navbar__item nav-item mb-2 mb-lg-0 ml-2 ml-md-5">
            <Link className="nav-link text-uppercase" to={globalLinks.faq}>
              faq
            </Link>
          </li>
          <li className="navbar__item nav-item mb-2 mb-lg-0 ml-2 ml-md-5">
            <Link className="nav-link text-uppercase" to={globalLinks.contact}>
              contact
            </Link>
          </li>
        </ul>

      </div>

      <div className="pt-2 pt-4 d-flex flex-column align-items-center font-weight-light position-relative top-bar top-bar_size_small">
        <div className='text-center'>
          © {year} Dropzik Digital Inc. All Rights Reserved.
        </div>
        <ul className="list-unstyled font-weight-light w-100 d-flex flex-column flex-md-row justify-content-center align-items-center flex_1">
          <li className="nav-item m-0 navbar__item">
            <Link className="text-white p-0" to={globalLinks.licenceContract}>
              Contrat de licence |&nbsp;
            </Link>
          </li>
          <li className="nav-item m-0 navbar__item">
            <Link className="text-white p-0" to={globalLinks.conditions}>
              Terms {`&`} Conditions |&nbsp;
            </Link>
          </li>
          <li className="nav-item m-0 navbar__item">
            <Link className="text-white p-0" to={globalLinks.impressum}>
              Impressum
            </Link>
          </li>
        </ul>

      </div>

    </footer>
  )
}
