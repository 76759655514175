import { Link, useStaticQuery, graphql } from "gatsby";
import React, { useState } from "react";

import SigninIcon from "../svg/signin";
import SignupIcon from "../svg/signup";

import menuConfig from "../../assets/data/menu.json";
import globalLinks from "../../assets/data/links.json";
import { KeyValueObject } from "../../types/shared";

import ArrowDownSvg from '../../assets/icons/arrow-down.svg';
import "./style.css";

interface HeaderProps {
  siteTitle?: string;
  size?: "small" | "auto",
  className?: string;
}
/**
 * @name Header
 * @description navbar menu at header of the page
 * @author siemah
 */
const Header = ({ siteTitle, className, size = "small" }: HeaderProps) => {

  const [mobileMenuState, setMobileMenuState] = useState(false);
  let togglerClass;
  let navbarCollapse = '';

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.svg" }) {
        publicURL
      }
    }
  `);

  /**
   * render menu items
   * @param items list of menu items
   * @param isSubmenu verify if those items belong to a parent item ot not(true, false respectively)
   */
  const renderNavItems = (items: KeyValueObject<any>[], isSubmenu = false) => {
    return items.map((navItem: KeyValueObject<any>) => {
      let to;
      let className = `nav-link`;

      if (navItem?.submenu) {
        className += ` dropdown-toggle`;
        to = '#';
      } else {
        to = String(navItem.link);
      }
      // render a submenu links
      if (isSubmenu) {
        return (
          <Link className="dropdown-item navbar__dropdown-item" to={to} key={to}>{navItem.label}</Link>
        )
      } else {
        // render main menu links
        return (
          <li className="nav-item navbar__item mb-2 mb-lg-0 dropdown text-uppercase text-center" key={to}>
            <Link
              activeClassName="active"
              className={`with-hover ${className}`}
              to={to}>
              {String(navItem.label)}
              {
                navItem?.submenu && ArrowDownSvg && (
                  <svg className='align-baseline ml-1' width="15" height="16" viewBox="0 0 15 16" fill="none">
                    <path d="M2.81229 7.0296L7.59326 13.4351L12.3742 7.0296" stroke="white" strokeWidth="2" strokeLinecap="round" />
                  </svg>

                )
              }
            </Link>
            {
              navItem?.submenu && (
                <div className="dropdown-menu mt-0" aria-labelledby="navbarDropdown">
                  {renderNavItems(navItem?.submenu, true)}
                </div>
              )
            }
          </li>
        )
      }
    })
  };

  const onToggleMenu = (evnt: React.MouseEvent<HTMLButtonElement>) => {
    setMobileMenuState(!mobileMenuState);
  }

  if (mobileMenuState) {
    navbarCollapse = 'show';
  } else {
    togglerClass = 'collapsed';
  }

  return (
    <nav className={`navbar navbar-expand-lg navbar-light bg-transpanert fixed-top size_${size} ${className || ''}`}>

      <Link title={siteTitle} className="navbar-brand navbar-brand__block p-0 m-0" to='/'>
        <img className='image-container' src={data?.placeholderImage?.publicURL} />
      </Link>
      <button onClick={onToggleMenu} className={`navbar-toggler ${togglerClass}`} type="button" aria-controls="navbarHeaderMenuContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className={`collapse navbar-collapse ${navbarCollapse}`} id="navbarHeaderMenuContent">
        <div className="flex_1"></div>
        <ul className="navbar-nav d-flex justify-content-center align-items-center flex_1" style={{ width: '100%' }}>
          {renderNavItems(menuConfig)}
        </ul>
        <ul className="navbar-nav d-flex justify-content-center justify-content-lg-end align-items-center flex_1">
          <li className="nav-item mb-2 mb-lg-0 navbar__item">
            <a className="nav-link text-uppercase" target="_blank" rel="noopener noreferrer" href={globalLinks.signin}>
              <SigninIcon className='mr-1 mr-xl-3' />
              <span>
                connexion
                <span className="sr-only">(current)</span>
              </span>
            </a>
          </li>
          <li className="nav-item mb-2 mb-lg-0 navbar__item">
            <a target="_blank" rel="noopener noreferrer" className='btn border-0 text-white text-decoration-none text-uppercase btn__gradient py-2 nav-link' href={globalLinks.signup}>
              <SignupIcon className='mr-1 mr-xl-3' />
              inscription
            </a>
            {/* <Link className="nav-link text-uppercase" >inscription <span className="sr-only">(current)</span></Link> */}
          </li>
        </ul>
      </div>

    </nav>
  );
}

export default Header;
