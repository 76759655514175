import React from 'react';
import { KeyValueObject } from '../../types/shared';

export default function SignupIcon({ className }: KeyValueObject<string | undefined>) {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" className={`d-icon ${className}`}>
      <g clipPath="url(#clip0)">
        <path d="M12.4756 0.0250244C8.49868 0.0250244 5.26318 3.26057 5.26318 7.23748C5.26318 9.70081 6.50483 11.8793 8.39487 13.181C6.63027 13.79 5.01289 14.7958 3.6541 16.1546C1.29771 18.5111 0 21.6439 0 24.9763H1.94932C1.94932 19.172 6.67139 14.45 12.4756 14.45C16.4526 14.45 19.6881 11.2144 19.6881 7.23748C19.6881 3.26052 16.4526 0.0250244 12.4756 0.0250244ZM12.4756 12.5007C9.57353 12.5007 7.2125 10.1396 7.2125 7.23752C7.2125 4.33542 9.57353 1.97434 12.4756 1.97434C15.3777 1.97434 17.7388 4.33538 17.7388 7.23752C17.7388 10.1397 15.3777 12.5007 12.4756 12.5007Z" fill="white" />
        <path d="M20.9064 18.9327V14.8391H18.9571V18.9327H14.8635V20.8821H18.9571V24.9756H20.9064V20.8821H25V18.9327H20.9064Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
